@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.FAQPage{
    animation: fadeIn 1s;
}
.FAQPage h2{
    text-align: center;
    margin-bottom: 5%;
}

.faq{
    width: 60%;
    margin: 0 auto;
    margin-bottom: 3%;
}

.faq-title p{
    text-decoration: underline;
    font-size: 1.3rem;
    margin-bottom: 0;
}


@media(max-width: 810px){
    .faq{
        width: 80%;
    }
}