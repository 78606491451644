.SalesBanner{
    margin-top: 4%;
    background: rgb(0,0,136);
    padding: 1% 0 1% 0;
    margin-bottom: 0;
    text-align: center;
}

.SalesBanner h3{
    margin-bottom: 1%;
}

@media(max-width: 414px){
    .SalesBanner h3{
        font-size: 1.3rem;
    }
}