@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.About{
    width: 80%;
    margin: 0 auto;
    animation: fadeIn 1s;
}


.AboutSectionImg{
    width: 30%;
}

.AboutSectionImg img{
    width: 100%;
    display: block;
    margin-bottom: 2%;
    border-radius: 20px;
}

.AboutSectionImg h5, .AboutSectionImg h6{
    margin: 0;
}

.AboutSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4%;
}

.AboutSectionText{
    width: 60%;
}

.AboutSectionText h3{
    margin-top: 0;
}

.AboutSection2{
    flex-direction: row-reverse;
}

@media(max-width: 601px){
    .AboutSection{
        flex-direction: column;
        margin-bottom: 20%;
        align-items: baseline;
    }
    .AboutSectionImg{
        width: 40%;
        margin-bottom: 5%;
    }
    .AboutSectionText{
        width: 100%;
    }
    .AboutSectionText h3{
        display: none;
    }

    .AboutSection2{
        align-items: end;
    }
}

@media(max-width: 414px){
    .AboutSectionImg{
        width: 50%;
    }
    .AboutSectionImgTitle{
        width: 150%;
    }
}