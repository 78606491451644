@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.GetStarted{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    animation: fadeIn 1s;
}

.GetStarted h3{
    margin-bottom: 0;
}

.GetStartedText{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 55%;
    position: relative;
    margin: 0 auto;
}

.GetStartedText p{
    margin-bottom: 4%;
    margin-top: 0;
    text-align: justify;
    text-align-last: center;
    margin: 0 auto;
}

#GetStartedSubtext{
    margin-top: 0.5%;
    margin-bottom: 0;
}

#CalendarErrorDisclaimer{
    margin-top: 0;
    padding-top: 0;
    line-height: 0;
    font-size: 0.8rem;
    margin-bottom: 3%;
}

.Calendar1440, .Calendar4k, .Calendar720, .CalendarTablet{
    display: none;
}


@media(max-width: 1366px){
    .Calendar1080, .Calendar720, .Calendar4k, .CalendarTablet{
        display: none;
    }
    .Calendar720{
        display: block;
    }
    #CalendarErrorDisclaimer, #CalendarErrorDisclaimer a{
        font-size: 0.533rem;
    }
}


@media(max-width: 810px){
    .Calendar1080, .Calendar720, .Calendar4k, .Calendar1440p{
        display: none;
    }
    .CalendarTablet{
        display: block;
    }

    .GetStartedText{
        width: 80%;
    }
}





@media(min-width: 2560px){
    .Calendar1080, .Calendar4k, .Calendar720, .CalendarTablet{
        display: none;
    }
    .Calendar1440{
        display: block;
    }
    #CalendarErrorDisclaimer, #CalendarErrorDisclaimer a{
        font-size: 1.2rem;
    }
}

@media(min-width: 3840px){
    .Calendar1080, .Calendar1440, .Calendar720, .CalendarTablet{
        display: none;
    }
    .Calendar4k{
        display: block;
    }
    #CalendarErrorDisclaimer, #CalendarErrorDisclaimer a{
        font-size: 1.6rem;
    }
}