.Footer{
    background: rgba(0,0,0,0.5);
    margin-top: 0;
}

.FooterTop{
    display: flex;
    width: 60%;
    margin: 0 auto;
    padding: 2% 0;
    justify-content: space-between;
}


.FooterRight a{
    display: block;
    font-size: 1.1rem;
    margin-bottom: 5%;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.25s;
}

.FooterRight a:hover, .FooterLeftLinks a:hover{
    color: grey;
    transition-duration: 0.25s;
}

.FooterLeft img{
    /* border: 1px solid white; */
    margin-bottom: -3%;
    display: block;
    width: 95%;
}
.FooterLeftLinks{
    margin-top: 0;
    text-align: center;
    font-size: 2.5rem;
    /* border: 1px solid white; */
}
.FooterLeftLinks a{
    margin: 0 2%;
}

.FooterLeft, .FooterRight{
    /* border: 1px solid white; */
    width: 45%;
}

.FooterBottom p{
    margin-bottom: 0;
    padding-bottom: 0.5%;
    text-align: center;
}

@media(max-width: 1366px){
    .FooterRight a{
        font-size: 0.732rem;
    }
    .FooterLeftLinks a{
        font-size: 1.665rem;
    }
}





@media(min-width: 2560px){
    .FooterRight a{
        font-size: 1.65rem;
    }
    .FooterLeftLinks a{
        font-size: 3.75rem;
    }
}

@media(min-width: 3840px){
    .FooterRight a{
        font-size: 2.2rem;
    }
    .FooterLeftLinks a{
        font-size: 5rem;
    }
}