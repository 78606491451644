@keyframes fadeInBackground{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeInText{
    0% {opacity: 0;}
    50%{opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

@keyframes slideInLeft{
    0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
}

@keyframes slideInRight{
    0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
}

.HomeWrapper{
    width: 100%;
    text-align: center;
    animation: fadeIn 1s;
}

.HomeTop{
    width: 100%;
    max-height: 95vh;
    overflow-y: hidden;
    position: relative;
    z-index: 0;
}

.HomeTop img{
    width: 100%;
    position: relative;
    bottom: 10vh;
}

.HomeTopText{
    position: absolute;
    height: 95vh;
    background: rgba(50,50,50,0.7);
    top: 0;
    width: 100%;
    margin: 0;
    animation: fadeInBackground 2s;
    z-index: 0;
}

.HomeTopTextInner{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 85%;
    animation: fadeInText 4s;
    z-index: 1;
}

.HomeTopTextInner h1{
    margin-bottom: 1%;
    margin-top: 0;
}

.HomeTopTextInner h5{
    margin-bottom: 2%;
    margin-top: 1%;
}

.HomeTopTextInner hr{
    width: 70%;
    display: block;
}

.HomeMiddle{
    width: 100%;
    overflow-x: hidden;
}

.HomeMiddleTitle h4{
    margin-top: 2%;
    margin-bottom: 0;
}

.HomeMiddleService{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#HomeMiddleService2{
    margin-top: 2%;
    flex-direction: row-reverse;
}

.HomeMiddleServiceText{
    width: 50%;
    text-align: left;
}

.HomeMiddleServiceText h6{
    margin-bottom: 2%;
}

.HomeMiddleServiceText hr{
    width: 10%;
    margin-right: 100%;
}

.HomeMiddleServiceText p{
    margin-top: 2%;
}

.HomeMiddleServiceImg{
    width: 20%;
}

.HomeMiddleServiceImg img{
    width: 100%;
    border-radius: 50%;
}

@media(max-width: 1600px){
    .HomeTopTextInner h1{
        font-size: 3.3rem;
    }
    .HomeTopTextInner h5{
        font-size: 1.6rem;
    }
}

@media(max-width: 1536px){


    .HomeTopTextInner h1{
        font-size: 3.1rem;
    }
    .HomeTopTextInner h5{
        font-size: 1.5rem;
    }
}

@media(max-width: 1440px){
    .HomeTop img{
        bottom: 6vh;
    }

    .HomeTopTextInner{
        bottom: 5%;
    }

    .HomeTopTextInner h1{
        font-size: 2.9rem;
    }
    .HomeTopTextInner h5{
        font-size: 1.5rem;
    }
}

@media(max-width: 1366px){
    .HomeTop img{
        bottom: 8vh;
    }

    .HomeTopTextInner{
        bottom: 0%;
    }

    .HomeTopTextInner h1{
        font-size: 2.8rem;
    }
    .HomeTopTextInner h5{
        font-size: 1.4rem;
    }
}

@media(max-width: 1280px){
    .HomeTop img{
        bottom: 10vh;
    }
    .HomeTopTextInner{
        bottom: 3vh;
    }
    .HomeTopTextInner h1{
        font-size: 2.6rem;
    }
    .HomeTopTextInner h5{
        font-size: 1.3rem;
    }
}

@media(max-width: 1024px){
    .HomeTopTextInner{
        bottom: 21%;
    }
    .HomeTopTextInner h1{
        font-size: 2.1rem;
    }
    .HomeTopTextInner h5{
        font-size: 1rem;
    }

    .HomeTop img{
        bottom: 5vh;
    }
}



/* ------------- TABLETS ------------- */

@media(max-width: 962px){
    .HomeTopTextInner{
        bottom: 5%;
    }
}

@media(max-width: 810px){
    .HomeTopTextInner{
        height: fit-content;
        top: 25vh;
        animation: none;
    }

    .HomeTopTextInner h1{
        font-size: 1.6rem;
    }
    .HomeTopTextInner h5{
        font-size: 0.9rem;
    }

    .HomeTopText{
        animation: none;
    }

    .HomeTop img{
        bottom: -2vh;
    }

    .HomeMiddleService{
        width: 100%;
    }
}

@media(max-width: 601px){
    .HomeTopTextInner h1{
        font-size: 1.2rem;
    }
    .HomeTopTextInner h5{
        font-size: 0.7rem;
    }
    .HomeTopTextInner{
        top: 24vh;
    }
}

@media(max-width: 414px){
    .HomeTop{
        height: 90vh;
    }
    .HomeTop img{
        height: 90vh;
        object-fit: cover;
    }
    .HomeTopText{
        animation: fadeInBackground 2s;
    }
    .HomeTopTextInner{
        animation: fadeInText 4s;
        top: auto;
        bottom: 7vh;
    }

    .HomeMiddleTitle{
        margin-top: 15%;
    }
    .HomeMiddleService{
        flex-direction: column-reverse;
        margin-top: 15%;
    }
    #HomeMiddleService2{
        margin-top: 15%;
        flex-direction: column-reverse;
    }
    .HomeMiddleServiceText{
        width: 90%;
        text-align: center;
    }
    .HomeMiddleServiceImg{
        width: 80%;
        height: 150px;
        overflow: hidden;
    }

    .HomeMiddleServiceImg img{
        border-radius: 0;
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .HomeMiddleService hr{
        margin-left: auto;
        margin-right: auto;
    }
}