@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.Industries{
    text-align: center;
    animation: fadeIn 1s;
}

.Industries h3{
    margin-bottom: 0;
}

.IndustryList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 87%;
    margin: 5% auto 0 auto;
}

.Industry{
    width: 25%;
    margin: 0 1% 2% 0;
    border-bottom: 1px solid white;
}

.Industry h6{
    margin: 1% 0;
    transition-duration: 0.25s;
}

.IndustryImg{
    width: 100%;
    margin: 0 auto;
}

.IndustryImg img{
    width: 100%;
    display: block;
    margin-bottom: 0;
    transition-duration: 0.25s;
}

.IndustryList a{
    text-decoration: none;
}

.IndustryList a:hover .IndustryImg img{
    opacity: 60%;
    scale: 1.1;
    transition-duration: 0.25s;
}

.IndustryList a:hover h6{
    color: grey;
    transition-duration: 0.25s;
}

.IndustryImg{
    overflow: hidden;
}