@keyframes slideInRight{
    0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
}

@keyframes slideOutLeft{
    0% {
        transform: translateX(0);
    }
    100%{
        transform: translateX(100%);
    }
}

nav{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 50;
}

.NavBanner{
    background: rgb(0,0,136)
}

.NavBanner p{
    margin: 0.4% 3%;
    text-align: right;
}
.NavBanner p span{
    margin-left: 1%;
    margin-right: 1%;
}

.NavMain{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
}

.NavLeft{
    padding: 0.5%;
    width: 17%;
}
.NavLeft img{
    width: 100%;
    display: block;
    margin: 0;
}

.NavMiddle{
    width: 50%;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: right;
}

.NavMiddle a{
    font-size: 1.25rem;
    margin: 0 3.5%;
    text-decoration: none;
    transition-duration: 0.25s;

    position: relative;
}

.NavMiddle a::before{
    content: "";
    position: absolute;
    top: 105%; 
    left: 50%;
    width: 0;
    height: 2px; 
    background-color: grey; 
    transition: width 0.25s ease; 
    transform: translate(-50%, -50%);
}

.NavMiddle a:hover::before{
    width: 80%;
    color: grey;
}

#HomeLink:hover::before{
    width: 0;
}

#HomeLink{
    line-height: 80%;
}

.NavMiddle a:hover{
    color: grey;
    transition-duration: 0.25s;
}

.NavRight{
    width: 10%;
    text-align: center;
}

.NavRight a{
    font-size: 1.5rem;
    background: rgb(0,0,136);
    padding: 7.5% 15%;
    text-decoration: none;
    border-radius: 20px;
    transition-duration: 0.25s;
    position: relative;
}

.NavRight a::before{
    content: "";
    position: absolute;
    top: 78%; 
    left: 50%;
    width: 0;
    height: 2px; 
    background-color: white; 
    transition: width 0.25s ease; 
    transform: translate(-50%, -50%);
}

.NavRight a:hover::before{
    width: 55%;
    color: white;
}

.NavRight a:hover{
    background: rgb(0, 0, 92);
    transition-duration: 0.25s;
    outline: 2px solid rgba(0, 0, 0, 0.65);
    outline-offset: -2px;
}

.NavDropdown{
    background: rgba(0,0,0,0.65);
    position: relative;
    bottom: 0;
    width: 50%;
    padding: 1% 0;
    margin-left: auto;
    display: flex;
    justify-content: space-evenly;
    transform: translateX(100%);
    animation: 0.25s ease-out slideOutLeft;
}

.NavDropdown.open{
    transform: translateX(0);
    animation: 0.25s ease-out slideInRight;
}

.NavDropdown a{
    text-decoration: none;
    transition-duration: 0.25s;
    position: relative;
}

.NavDropdown a::before{
    content: "";
    position: absolute;
    top: 110%; 
    left: 50%;
    width: 0;
    height: 2px; 
    background-color: grey; 
    transition: width 0.25s ease; 
    transform: translate(-50%, -50%);
}

.NavDropdown a:hover::before{
    width: 80%;
    color: grey;
}

.NavDropdown a:hover{
    color: grey;
    transition-duration: 0.25s;
}

.NavSpacingBlock{
    opacity: 0;
    padding-top: 6.5%;
}

.NavSpacingBlock h1{
    margin: 0;
}

.TabletNav{
    display: none;
}



/* ----------------------- GENERAL FLOW FIX ----------------------- */
@media(max-width: 1800px){
    .NavRight{
        width: 13%;
    }
    .NavRight a{
        padding: 5.5% 15%;
    }
}


/* ----------------------- 720P MONITORS ----------------------- */
@media(max-width: 1366px){
    .NavMiddle a, .NavRight a{
        font-size: 0.8325rem;
    }
    .NavRight{
        width: 10%;
    }
    .NavRight a{
        padding: 7.5% 15%;
    }
}

@media(max-width: 962px){
    .NavRight{
        width: 13%;
    }
    .NavRight a{
        padding: 5.5% 15%;
    }
}

@media(max-width: 810px){
    .NavBanner, .NavMain, .NavDropdown{
        display: none;
    }

    #MobileGetStarted, #MobileNavOverlay{
        display: none;
    }

    #TabletGetStarted{
        border-top: 2px solid rgba(0,0,136, 0.65);
        border-bottom: 2px solid rgba(0,0,136, 0.65);
        text-align: center;
        background: rgba(0,0,0,0.85);
    }

    #TabletGetStarted a{
        font-size: 1.5rem;
        padding: 2%;
        display: block;
        text-decoration: none;
        width: 100%;
    }

    .TabletNav{
        display: block;
    }

    .TabletNavTop{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(0, 0, 0, 0.65);
    }

    .TabletNavTopImg{
        width: 40%;
        padding: 2%;
    }
    .TabletNavTopImg img{
        width: 100%;
        display: block;
    }
    .TabletNavBanner{
        background: rgba(0,0,136,0.85);
        padding: 1% 0;
    }
    .TabletNavBanner p{
        margin: 0.4% 3%;
        text-align: right;
    }
    .TabletNavBanner p span{
        margin-left: 1%;
        margin-right: 1%;
    }

    .TabletNavBottom{
        display: flex;
        justify-content: space-between;
        padding: 1% 5%;
        background: rgba(0, 0, 0, 0.85)
    }
    .TabletNavBottom a{
        font-size: 1.5rem;
        display: block;
        text-decoration: none;
    }

    .NavSpacingBlock{
        padding-top: 25%;
    }
    .TabletMenuToggle{
        background: none;
        border: none;
        font-size: 3.5rem;
        padding: 0;
        line-height: 0;
        margin-right: 5%;
    }
    .TabletMenuToggle:hover{
        cursor: pointer;
    }

    .TabletNavMenu{
        position: relative;
        bottom: 0;
        width: 100%;
        margin-left: auto;
        justify-content: space-evenly;
        transform: translateX(100%);
        animation: 0.25s ease-out slideOutLeft;
        height: 0;
    }
    .TabletNavMenu.open{
        transform: translateX(0);
        animation: 0.25s ease-out slideInRight;
    }
}

@media(max-width: 414px){
    .TabletMenuToggle{
        font-size: 3rem;
    }

    .TabletNavBottom{
        flex-direction: column;
        text-align: right;
    }
    .TabletNavBottom a{
        margin-top: 3%;
        margin-bottom: 3%;
    }

    #MobileGetStarted, #MobileNavOverlay{
        display: block;
    }

    #MobileNavOverlay{
        height: 50vh;
        width: 100%;
    }
    

    #TabletGetStarted{
        display: none;
    }

    .NavSpacingBlock{
        padding-top: 15%;
    }
}



@media(min-width: 2560px){
    .NavMiddle a, .NavRight a{
        font-size: 1.875rem;
    }
}


@media(min-width: 3840px){
    .NavMiddle a, .NavRight a{
        font-size: 2.5rem;
    }
}
