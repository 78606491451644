@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.ServicePage{
    text-align: center;
    animation: fadeIn 1s;
}

.ServicePageContent{
    width: 95%;
    margin: 3.5% auto;
    display: flex;
    justify-content: space-between;
}

.Service{
    width: 23%;
    position: relative;
    display: block;
}

.ServiceImg{
    width: 100%;
    overflow: hidden;
    position: relative;
}

.ServiceImg img{
    width: 100%;
    transition-duration: 0.25s;
    display: block;
}

.Service h5{
    margin-top: 7%;
    transition-duration: 0.25s;
}

.ServicePageContent a{
    text-decoration: none;
}

.ServicePageContent a:hover h5{
    color: grey;
    transition-duration: 0.25s;
}

.ServicePageContent a:hover .ServiceImg img{
    transform: scale(1.1);
    opacity: 0.6;
    cursor: pointer;
}

.ServiceDedicatedPage{
    animation: fadeIn 1s;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    align-items: center;
}

.ServiceDedicatedLeft{
    width: 35%;
}

.ServiceDedicatedLeft img{
    width: 100%;
    border-radius: 20px;
}

.ServiceDedicatedRight{
    width: 60%;
}

.ServiceDedicatedRight h3{
    margin-top: 0;
}

.UnderlineSpan{
    font-weight: bold;
    text-decoration: underline;
}

@media(max-width: 414px){
    .ServicePageContent{
        flex-direction: column;
    }

    .Service{
        width: 80%;
        margin: 0 auto 4% auto;
    }

    .ServiceImg{
        height: 150px;
    }
    .ServiceImg img{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .Service h5{
        margin-top: 1%;
    }

    .ServiceDedicatedPage{
        flex-direction: column;
    }

    .ServiceDedicatedLeft{
        width: 60%;
    }
    .ServiceDedicatedRight{
        width: 90%;
        margin-top: 5%;
    }

    .ServiceDedicatedRight h3{
        text-align: center;
    }
    .ServicePageContent a:hover .ServiceImg img{
        transform: scale(1);
        transform: translateY(-50%);
    }

}