@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.LegalNav{
    display: flex;
    width: 60%;
    margin: 0 auto;
    justify-content: space-between;
}


.TandC, .PrivacyPolicy{
    width: 80%;
    margin: 0 auto;
    animation: fadeIn 1s;
}

.TandC h3, .PrivacyPolicy h3{
    text-align: center;
}

.TandC h5, .PrivacyPolicy h5{
    margin-bottom: 0;
    margin-top: 4%;
}