body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(33,33,33);
}

input, textarea, select, button {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 8px; 
  font-size: 16px;
}
a, button {
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, a, span, button, label, input, textarea{
  color: white;
}

h1,h2,h3,h4,h5,h6,button,input,textarea{
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold;
}

p,a,span,li,label,option{
  font-family: "Karla", sans-serif !important;
}

input, textarea{
  font-size: 1rem;
}

h1{
  font-size: 3.5rem;
}
h2{
  font-size: 3.0rem;
}
h3{
  font-size: 2.5rem;
}
h4{
  font-size: 2.1rem;
}
h5{
  font-size: 1.7rem;
}
h6{
  font-size: 1.2rem;
}





@media(max-width: 1366px){
  p, a, span, button, label, input, textarea{
    font-size: 0.666rem;
  }

  h1{
    font-size: 2.333rem;
  }
  h2{
    font-size: 1.998rem;
  }
  h3{
    font-size: 1.665rem;
  }
  h4{
    font-size: 1.398rem;
  }
  h5{
    font-size: 1.132rem;
  }
  h6{
    font-size: 0.799rem;
  }
}




@media(min-width: 2560px){
  p, a, span, button, label, input, textarea{
    font-size: 1.5rem;
  }
  h1{
    font-size: 5.25rem;
  }
  h2{
    font-size: 4.5rem;
  }
  h3{
    font-size: 3.75rem;
  }
  h4{
    font-size: 3.15rem;
  }
  h5{
    font-size: 2.55rem;
  }
  h6{
    font-size: 1.8rem;
  }
}

@media(min-width: 3840px){
  h1{
    font-size: 7rem;
  }
  h2{
    font-size: 6rem;
  }
  h3{
    font-size: 5rem
  }
  h4{
    font-size: 4.2rem;
  }
  h5{
    font-size: 3.4rem;
  }
  h6{
    font-size: 2.4rem;
  }
  p, a, span, button, input, label, textarea{
    font-size: 2rem;
  }
}