@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.ContactPage{
    text-align: center;
    width: 50%;
    margin: 0 auto;
    animation: fadeIn 1s;
}

.ContactPage h3{
    margin-bottom: 0;
}

.FormInput label, .FormInputTA label{
    visibility: hidden;
    font-size: 0;
}

.FormTop, .FormMiddle, .FormBottom{
    display: flex;
    justify-content: space-between;
}

.FormInput{
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 2%;
    position: relative;
}

.FormInput input, .FormInputTA textarea{
    width: 100%;
    padding: 2.5%;
    background: rgba(0,0,0,0);
    border: none;
    border-bottom: 1px solid white;
}

.FormInput input:focus, .FormInputTA textarea:focus{
    outline: none;
    border-bottom: 2px solid white;
}

.error{
    color: red;
    position: absolute;
    font-size: 0.8rem;
    margin-top: 0.25%;
    margin-left: 0.5%;
    margin-bottom: 0;
    top: 100%;
}

.FormInputTA{
    width: 100%;
    position: relative;
}

.FormInputTA textarea{
    width: 100%;
}

.ContactPage button{
    margin-top: 2%;
    padding: 1.5% 7.5%;
    background: rgb(0,0,136);
    border: none;
    position: relative;
    transition-duration: 0.25s;
}

.ContactPage button:hover{
    cursor: pointer;
    background: rgb(0, 0, 92);
    transition-duration: 0.25s;
    outline-offset: -2px;
}

.ContactPage button:disabled{
    opacity: 10%;
    background: grey;
    transition-duration: 0.25s;
}

.ContactPage button:disabled:hover{
    cursor:not-allowed;
}

.ContactPage button::before{
    content: "";
    position: absolute;
    top: 78%; 
    left: 50%;
    width: 0;
    height: 2px; 
    background-color: white; 
    transition: width 0.25s ease; 
    transform: translate(-50%, -50%);
}

.ContactPage button:hover::before{
    width: 23%;
    color: white;
}

.ContactPage button:disabled:hover::before{
    width: 0%;
    color: white;
}

.hidden{
    display: none;
}

.ContactError h3, .ContactError p{
    color: red;
}

@media(max-width: 1366px){
    .error{
        font-size: 0.5328rem;
    }
}


@media(max-width: 810px){
    .ContactPage{
        width: 70%;
    }
}

@media(max-width: 414px){
    .ContactPage h3{
        font-size: 1.3rem;
    }
    .ContactPage p{
        margin-bottom: 15%;
    }
    .FormTop, .FormMiddle{
        flex-direction: column;
    }
    .FormInput{
        width: 100%;
    }
}


@media(min-width: 2560px){
    .error{
        font-size: 1.2rem;
    }
}

@media(min-width: 3840px){
    .error{
        font-size: 1.6rem;
    }
}