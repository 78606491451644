@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}
.Attributions{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    animation: fadeIn 1s;
}

.Attributions br{
    color: white;
}
.PageTitle{
    margin-top: 5%;
    margin-bottom: 0;
}

.Attributions h3{
    margin-bottom: 0;
}

.Attribution{
    padding-top: 0.5%;
    position: relative;
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-between;
    border-bottom: 1px solid grey;
}

.Attribution a, .Attribution p{
    display: block;
    font-size: 1rem;
    margin: 0;
}

.Attribution a{
    font-style: italic;
}

@media(max-width: 810px){
    .Attribution{
        width: 80%;
    }
}